import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import IndexPage from "./pages/index/IndexPage";
import PolicyPage from "./pages/policy/PolicyPage";
import ErrorPage from "./pages/error/ErrorPage";
import Turnkey from "./pages/schemes/Turnkey";
import Consultation from "./pages/schemes/Consultation";
import {useCallback, useEffect, useState} from "react";
import PortalPopup from "@components/PortalPopup";
import PopupRequest from "@components/popup/PopupRequest";
import PopUpQuestion from "@components/popup/PopUpQuestion";


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const [showPopupRequest, setShowPopupRequest] = useState(false);
  const [showPopupQuestion, setShowPopupQuestion] = useState(false);

  /// for new worked popup
  const onShowPopupRequest = useCallback(() => {
    setShowPopupRequest(true);
  }, []);
  const onHidePopupRequest = useCallback(() => {
    setShowPopupRequest(false);
  }, []);

  const onShowPopupQuestion = useCallback(() => {
    setShowPopupQuestion(true);
  }, []);
  const onHidePopupQuestion = useCallback(() => {
    setShowPopupQuestion(false);
  }, []);
  ///

  useEffect(() => {
    if (showPopupRequest) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = '';
    }
  }, [showPopupRequest])

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/consultation/hzschemes":
        title = "Схемы работы";
        metaDescription = "";
        break;
      case "/3":
        title = "";
        metaDescription = "";
        break;
      case "/2":
        title = "";
        metaDescription = "";
        break;
      case "/404":
        title = "";
        metaDescription = "";
        break;
      case "/1":
        title = "";
        metaDescription = "";
        break;
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={
          <IndexPage
            onShowPopupRequest={onShowPopupRequest}
            onHidePopupRequest={onHidePopupRequest}
            onShowPopupQuestion={onShowPopupQuestion}
            onHidePopupQuestion={onHidePopupQuestion}
          />} />
        {/*<Route path="/consultation/hzschemes" element={<MobileMenu />} />*/}
        <Route path="/policy" element={<PolicyPage onShowPopupRequest={onShowPopupRequest} onHidePopupRequest={onHidePopupRequest} />} />
        <Route path="/schemes/turnkey" element={
          <Turnkey
            onShowPopupRequest={onShowPopupRequest}
            onHidePopupRequest={onHidePopupRequest}
            onShowPopupQuestion={onShowPopupQuestion}
            onHidePopupQuestion={onHidePopupQuestion}
          />
        } />
        <Route path="/schemes/consultation" element={
          <Consultation
            onShowPopupRequest={onShowPopupRequest}
            onHidePopupRequest={onHidePopupRequest}
            onShowPopupQuestion={onShowPopupQuestion}
            onHidePopupQuestion={onHidePopupQuestion}
          />
        } />

        <Route path="/*" element={<ErrorPage onShowPopupRequest={onShowPopupRequest} onHidePopupRequest={onHidePopupRequest} />} />

        {/*<Route path="/123" element={<Component2 />} />*/}
      </Routes>

      {/*/ new worked popup  */}
      {showPopupRequest && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.5)"
          placement="Centered"
          onOutsideClick={onHidePopupRequest}
        >
          <PopupRequest onClose={onHidePopupRequest} />
        </PortalPopup>
      )}

      {showPopupQuestion && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.5)"
          placement="Centered"
          onOutsideClick={onHidePopupQuestion}
        >
          <PopUpQuestion onClose={onHidePopupQuestion} />
        </PortalPopup>
      )}
      {/*/ end worked popup */}
    </>
  );
}
export default App;
