import { FunctionComponent } from "react";
import ConsultationSupportDiv from "@components/ConsultationSupportDiv";
import QuestionsForm from "@components/QuestionsForm";
import QuestionsFilter from "@components/QuestionsFilter";
import Footer from "@components/footer/Footer";
import Header from "@components/header/header";
import FindManufacturer from "@components/FindManufacturer/FindManufacturer";
import AccordionQuestions from "@components/accordion/AccordionQuestions";
import QuestionsCard from "@components/QuestionsCard";

// images
import QuestionImage from "@images/icons-8020.svg";
// styles
import "./Consultation.css";
//
const Consultation: FunctionComponent = (props) => {
  const {onShowPopupRequest, onHidePopupRequest, onShowPopupQuestion, onHidePopupQuestion} = props;

  return (
    <>
      <div className="div96 padding-header">
        <Header
          onShowPopupRequest={onShowPopupRequest}
          useNavLinkWithAnchor={true}
        />
        <ConsultationSupportDiv
          onShowPopupRequest={onShowPopupRequest}
        />
        <QuestionsForm />

        <QuestionsFilter
          paymentIconUrl={QuestionImage}
          paymentText="Наша комиссия"
          paymentTypeText="Вы оплачиваете только комиссию "
          onShowPopupRequest={onShowPopupRequest}
        />

        <FindManufacturer
          vectorImageUrl="../vector-266.svg"
          openPopUpDonePopup={onShowPopupRequest}
        />
        <div className="questions">
          <div className="heading">
            <h2 className="h2">FAQ</h2>
          </div>
          <div className="questions__wrap">
            <AccordionQuestions />
            <QuestionsCard
              onShowPopupRequest={onShowPopupQuestion}
            />
          </div>
        </div>

        <Footer
          onShowPopupRequest={onShowPopupRequest}
          useNavLinkWithAnchor={true}
        />
      </div>

    </>
  );
};

export default Consultation;
