import { FunctionComponent, useMemo, useCallback } from "react";
import CSS, { Property } from "csstype";
import { useNavigate } from "react-router-dom";
import BtnPrimary from "../components/BtnPrimary";
import "./TariffCard.css";

// import images
import Check from "@images/icons-16px1.svg";
import BtnIcon from "@images/icons-16px.svg";
//

type TariffCardType = {
  to: string;
  prop?: string;
  prop1?: string;
  prop2?: string;
  icons16px?: string;
  prop3?: string;
  prop4?: string;
  icons16px1?: string;
  prop5?: string;
  prop6?: string;

  /** Style props */
  headingsubtitleFlex?: Property.Flex;
};

const TariffCard: FunctionComponent<TariffCardType> = ({
  to,
  prop,
  prop1,
  prop2,
  headingsubtitleFlex,
  icons16px,
  prop3,
  prop4,
  icons16px1,
  prop5,
  prop6,
}) => {
  const headingsubtitleStyle: CSS.Properties = useMemo(() => {
    return {
      flex: headingsubtitleFlex,
    };
  }, [headingsubtitleFlex]);

  const navigate = useNavigate();

  const onButton2Click = useCallback(() => {
    navigate(to);
  }, [navigate]);

  return (
    <div className="advantages">
      <div className="imageheading">
        <img className="icon3" alt="" src={prop} />
        <div className="group">
          <h4 className="h42">{prop1}</h4>
          <div className="div121">{prop2}</div>
        </div>
      </div>
      <div className="headingsubtitle25" style={headingsubtitleStyle}>
        <div className="iconsubtitle">
          <img className="icons-16px11" alt="" src={Check} />
          <div className="div122">Ищем поставщика в Китае</div>
        </div>
        <div className="iconsubtitle">
          <img className="icons-16px11" alt="" src={Check} />
          <div className="div122">{prop3}</div>
        </div>
        <div className="iconsubtitle">
          <img className="icons-16px11" alt="" src={Check} />
          <div className="div122">{prop4}</div>
        </div>
        <div className="iconsubtitle">
          <img className="icons-16px11" alt="" src={Check} />
          <div className="div122">{prop5}</div>
        </div>
      </div>
      <div className="advantages-child" />
      <div className="price2">
        <div className="div126">Цена</div>
        <b className="b55">{prop6}</b>
      </div>
      <BtnPrimary
        openPopUpRequestPopup={onButton2Click}
        buttonAlignSelf="stretch"
        prop="Подробнее"
        icons16px={BtnIcon}
      />
    </div>
  );
};

export default TariffCard;
