import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import TariffCard from "../components/TariffCard";
import "./ProjectsForm.css";

// import images
import Tariff1 from "@images/72.svg"
import Tariff2 from "@images/721.svg"
//

const ProjectsForm: FunctionComponent = () => {

  return (
    <div className="projects1" id="schemaWork">
      <div className="heading11">
        <h2 className="h25">Схемы работы</h2>
      </div>
      <div className="cards">
        <TariffCard
          to="/schemes/consultation"
          prop={Tariff1}
          prop1="Консультационная"
          prop2="Найдем всю информацию о производителе, договоримся о цене и поставке, поможет с оформлением импорта в Россию."
          icons16px="icons-16px4.svg"
          prop3="Даем информацию о производителе - контакты, предварительные договоренности, фото и видео, переписки."
          prop4="Гайд как осуществить импорт самому"
          icons16px1="icons-16px3.svg"
          prop5="Контакты, информация по перевозке, таможенному оформлению заказа"
          prop6="Наша комиссия"
        />
        <TariffCard
          to="/schemes/turnkey"
          prop={Tariff2}
          prop1="Под ключ"
          prop2="Закупка для осуществляется в России. Не нужно регистрироваться на таможне, искать брокера, перевозчика, сравнивать цены и сроки, выбирать маршруты путей доставки."
          headingsubtitleFlex="unset"
          icons16px="icons-16px3.svg"
          prop3="Согласовываем сроки и цену"
          prop4="Привозим продукцию на свой контракт. "
          icons16px1="icons-16px4.svg"
          prop5="Занимаемся таможней и перевозкой вашего заказа, взаимодействием с поставщиком в Китае"
          prop6="Предоплата по расчету"
        />
      </div>
    </div>
  );
};

export default ProjectsForm;
