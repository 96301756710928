import {FunctionComponent, useState} from "react";
import AccordionQuestionsItem from "@components/accordion/AccordionQuestionsItem";
import "./Accordion.scss"

type Question = {
  id: Number;
  question: string;
  answer: string;
}

const AccordionQuestions: FunctionComponent = () => {
  const [data] = useState([
    {
      "id": 0,
      "question": 'Как мне оплатить товар и доставку?',
      "answer": 'Вы можете оплатить товар любым удобным для вас способом. Вы\n' +
        '                    можете обсудить все детали по оплате с нашим специалистом.\n' +
        '                    Мы работаем с юридическими и физическими лицами.'
    },
    {
      "id": 1,
      "question": 'Как мне оплатить товар и доставку?',
      "answer": 'Вы можете оплатить товар любым удобным для вас способом. Вы\n' +
        '                    можете обсудить все детали по оплате с нашим специалистом.\n' +
        '                    Мы работаем с юридическими и физическими лицами.'
    },
    {
      "id": 2,
      "question": 'Какие товары можно заказать?',
      "answer": 'Вы можете оплатить товар любым удобным для вас способом. Вы\n' +
        '                    можете обсудить все детали по оплате с нашим специалистом.\n' +
        '                    Мы работаем с юридическими и физическими лицами.'
    },
    {
      "id": 3,
      "question": 'Как пройти таможню?',
      "answer": 'Вы можете оплатить товар любым удобным для вас способом. Вы\n' +
        '                    можете обсудить все детали по оплате с нашим специалистом.\n' +
        '                    Мы работаем с юридическими и физическими лицами.'
    },
    {
      "id": 4,
      "question": 'Текст вопроса?',
      "answer": 'Вы можете оплатить товар любым удобным для вас способом. Вы\n' +
        '                    можете обсудить все детали по оплате с нашим специалистом.\n' +
        '                    Мы работаем с юридическими и физическими лицами.'
    }
  ])

  return (
    <div className="questions-list">
      {data.map((question) => {
        return (
          <AccordionQuestionsItem
            question={question.question}
            answer={question.answer}
            key={question.id}
          />
        )
      })}
    </div>
  )
}
export default AccordionQuestions;