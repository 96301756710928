import {FunctionComponent, useState} from "react";

import "./Accordion.scss"
// import images
import Close from "@images/icons-24px.svg";
import Expand from "@images/icons-24px1.svg";
//

type Question = {
  id: Number;
  question: string;
  answer: string;
}

const AccordionQuestionsItem: FunctionComponent <Question> = (question) => {
  const [openAccordion, setOpenAccordion] = useState(false);

  return (
    <div className={`question ${openAccordion ? 'question_expand' : ''}`}>
      <div className="question__body">
        <b className="question__body__question">{question.question}</b>
        <div className="question__body__answer">{question.answer}</div>
      </div>
      <button className="plus" onClick={() => setOpenAccordion(!openAccordion)}>
        {openAccordion ?
          <img className="icons-24px1" alt="" src={Close} />
            :
          <img className="icons-24px22" alt="" src={Expand} />
        }
      </button>
    </div>
  )
}
export default AccordionQuestionsItem;