import { FunctionComponent } from "react";
import "./ProjectsCard.css";

// import images
import Illustration from "@images/illustration-vector.svg";
//

const ProjectsCard: FunctionComponent = () => {
  return (
    <div className="projects-card1">
      <div className="headingsubtitle19">
        <div className="heading9">
          <h3 className="h34">Компания по производству дефлекторов</h3>
        </div>
        <div className="description">
          <div className="headingsubtitle20">
            <div className="div107">Задача:</div>
            <div className="div108">Поиск двухстороннего скотча и импорт</div>
          </div>
          <div className="headingsubtitle20">
            <div className="div107">Результат:</div>
            <div className="div108">
              Найден производитель акрилового двухстороннего скотча и заключен
              договор на поставку.
            </div>
          </div>
        </div>
        <div className="headingsubtitle-child" />
        <div className="price">
          <div className="price-comparison">
            <div className="subtitleheading">
              <div className="div107">Цена за 1 метр до:</div>
              <div className="tag-price">
                <b className="b49">12 ₽</b>
              </div>
            </div>
            <div className="subtitleheading">
              <div className="div107">Цена за 1 метр после обращения :</div>
              <div className="wrapper">
                <b className="b49">7,5 ₽</b>
              </div>
            </div>
          </div>

          <div className="price-comparison" style={{paddingTop: 0}}>

            <div className="subtitleheading"></div>
            <div className="subtitleheading">
              <div className="subtitleheading2">
                <div className="div107">Экономия потребности</div>
                <b className="b51">540 000 ₽</b>
              </div>
            </div>

          </div>
        </div>
      </div>
      <img
        className="illustration-vector-icon"
        alt=""
        src={Illustration}
      />
    </div>
  );
};

export default ProjectsCard;
