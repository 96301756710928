import {NavLink} from "react-router-dom";
import Logo from "@images/svg/logo_white.svg";
import LogoBlack from "@images/svg/logo_default.svg";
import IconShema from "@images/icons-20px.svg";
import IconShemaBlack from "@images/icons-20px5.svg";
import WhSubstrate from "@images/vector-194-stroke.svg";
import {FunctionComponent, useEffect, useState} from "react";

import MobileMenu from "./MobileMenu";

import { HashLink as Link } from "react-router-hash-link";

const Header: FunctionComponent = (props: {
  onShowPopupRequest: any;
  whiteStyle?: boolean;
  useNavLinkWithAnchor?: boolean;
}) => {
  const {onShowPopupRequest, whiteStyle, useNavLinkWithAnchor} = props;

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [whiteHeader] = useState(whiteStyle);
  const [useNavLink] = useState(useNavLinkWithAnchor);

  useEffect(() => {
    if (showMobileMenu) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
    }
  }, [showMobileMenu])

  return (
    <header className={`header ${whiteHeader ? 'header_white' : ''} ${showMobileMenu ? 'header_mobile-menu' : ''}`}>
      <NavLink to="/" className="logo-al">
        <img className="logo-icon" alt="" src={whiteHeader ? LogoBlack : Logo} />
      </NavLink>
      <div className="link">
        {/*{useNavLink ?*/}
          <Link className="a" to="/#howWork">Как это работает</Link>
          {/*// <a className="a" href="/site_providers#howWork" aria-hidden="true">Как это работает</a>*/}
        {/*}*/}
        <div className="dropdown-header">
          <div className="link-dropdown">
            <div className="div1">Схемы работы</div>
            <img className="icons-20px" alt="" src={whiteHeader ? IconShemaBlack : IconShema} />
          </div>
          <div className="dropdown-header1">
            <NavLink to="/schemes/consultation" className="div1">Консультационная</NavLink>
            <NavLink to="/schemes/turnkey" className="div1">Под ключ</NavLink>
          </div>
        </div>
        {/*{useNavLink ?*/}
          <Link className="a" to="/#ourProjects">Наши проекты</Link>
          {/*// <a className="a" href="/site_providers#ourProjects" aria-hidden="true">Наши проекты</a>*/}
        {/*// }*/}
        {/*{useNavLink ?*/}
          <Link className="a" to="/#faq">FAQ</Link>
          {/*<a className="a" href="/site_providers#faq" aria-hidden="true">FAQ</a>*/}
        {/*}*/}
      </div>

      {showMobileMenu &&
        <MobileMenu
          useNavlink={useNavLink}
          onShowPopupRequest={onShowPopupRequest}
          onCloseMobileMenu={() => { setShowMobileMenu(false) } }
        />
      }

      <button className="button" onClick={onShowPopupRequest}>
        <b className="b">Оставить заявку</b>
      </button>
      <button className="icons-24px" onClick={() => { setShowMobileMenu(true) }}>
        <img
          className="vector-194-stroke"
          alt=""
          src={WhSubstrate}
        />
        <img
          className="vector-195-stroke"
          alt=""
          src={WhSubstrate}
        />
        <img
          className="vector-196-stroke"
          alt=""
          src={WhSubstrate}
        />
      </button>
    </header>

  )
}

export default Header;