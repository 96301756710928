import { FunctionComponent } from "react";
import BtnPrimary from "../components/BtnPrimary";

// import images
import QuestionIllustration from "@images/icons-804.svg";
import BtnIcon from "@images/icons-16px11.svg";
// styles
import "./QuestionsCard.css";

const QuestionsCard: FunctionComponent = (props) => {
  const {onShowPopupRequest} = props;

  return (
    <>
      <div className="questions11">
        <img className="icons-809" alt="" src={QuestionIllustration} />
        <div className="headingsubtitle27">
          <h4 className="h43">Остались вопросы?</h4>
          <div className="div136">
            Оставьте свои данные и наш менеджер перезвонит вам что бы ответить
            на все ваши вопросы
          </div>
        </div>

        <BtnPrimary
          openPopUpRequestPopup={onShowPopupRequest}
          buttonAlignSelf="stretch"
          prop="Подробнее"
          icons16px={BtnIcon}
        />

      </div>
    </>
  );
};

export default QuestionsCard;
