import { FunctionComponent } from "react";
import SubmitApplication from "@components/ui/Buttons/SubmitApplication";

// images
import Check from "@images/icons-16px1.svg";
import RubleImage from  "@images/ruble-1@2x.png";
// styles
import "./QuestionsFilter.css";
//
type QuestionsFilterType = {
  paymentIconUrl?: string;
  paymentText?: string;
  paymentTypeText?: string;
  onShowPopupRequest: any;
};

const QuestionsFilter: FunctionComponent<QuestionsFilterType> = ({
  paymentIconUrl,
  paymentText,
  paymentTypeText,
  onShowPopupRequest
}) => {
  return (
    <div className="questions13">
      <div className="heading13">
        <h2 className="h27">{`стоимость `}</h2>
      </div>
      <div className="projects-card3">
        <div className="icons-80-parent">
          <img className="icons-8014" alt="" src={paymentIconUrl} />
          <div className="parent1">
            <b className="b68">{paymentText}</b>
            <div className="iconsubtitle4">
              <img className="icons-16px17" alt="" src={Check} />
              <div className="div148">{paymentTypeText}</div>
            </div>
          </div>

          <SubmitApplication
            onShowPopupRequest={onShowPopupRequest}
          />

        </div>
        <img className="ruble-1-icon" alt="" src={RubleImage} />
      </div>
    </div>
  );
};

export default QuestionsFilter;
