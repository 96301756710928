import { FunctionComponent } from "react";
import "./CarTintCard.css";

// import images
import Illustration from "@images/illustration-vector1.svg";
//

const CarTintCard: FunctionComponent = () => {
  return (
    <div className="projects-card2">
      <div className="headingsubtitle22">
        <div className="heading10">
          <h3 className="h35">
            <p className="p26">{`Производитель автомобильной `}</p>
            <p className="p27">съемной тонировки</p>
          </h3>
        </div>
        <div className="description1">
          <div className="headingsubtitle23">
            <div className="div114">Задача:</div>
            <div className="div115">
              Поиск металлических уголков для крепления
            </div>
          </div>
          <div className="headingsubtitle23">
            <div className="div114">Результат:</div>
            <div className="div115">
              Нашли производителя уголков, заключили договор на поставку.
            </div>
          </div>
        </div>
        <div className="headingsubtitle-item" />
        <div className="price1">
          <div className="price-comparison1">
            <div className="subtitleheading3">
              <div className="div114">Цена за 1 шт до:</div>
              <div className="tag-price1">
                <b className="b52">11 ₽</b>
              </div>
            </div>
            <div className="subtitleheading3">
              <div className="div114">Цена за 1 шт после обращения :</div>
              <div className="container">
                <b className="b52">4 ₽</b>
              </div>
            </div>
          </div>


          <div className="price-comparison1" style={{paddingTop: 0}}>
            <div className="subtitleheading3">

            </div>
            <div className="subtitleheading3">
              <div className="subtitleheading5">
                <div className="div114">Экономия потребности</div>
                <b className="b54">3 500 000 ₽</b>
              </div>
            </div>

          </div>
        </div>
      </div>
      <img
        className="illustration-vector-icon1"
        alt=""
        src={Illustration}
      />
    </div>
  );
};

export default CarTintCard;
