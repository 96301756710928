import { FunctionComponent } from "react";
import SearchManufacturerForm from "@components/SearchManufacturerForm";
import QuestionsDiv from "@components/QuestionsDiv";
import QuestionsFilter from "@components/QuestionsFilter";
import Footer from "@components/footer/Footer";
import Header from "@components/header/header";

import "./Turnkey.css"
// import images
import Lens from "@images/icons-801.svg";
//
import FindManufacturer from "@components/FindManufacturer/FindManufacturer";
import AccordionQuestions from "@components/accordion/AccordionQuestions";
import QuestionsCard from "@components/QuestionsCard";
//

const Turnkey: FunctionComponent = (props) => {
  const {onShowPopupRequest, onHidePopupRequest, onShowPopupQuestion, onHidePopupQuestion} = props;

  return (
    <>
      <div className="div86 padding-header">
        <Header
          onShowPopupRequest={onShowPopupRequest}
          useNavLinkWithAnchor={true}
        />
        <SearchManufacturerForm
          onShowPopupRequest={onShowPopupRequest}
        />

        <QuestionsDiv />
        <QuestionsFilter
          paymentIconUrl={Lens}
          paymentText="Предоплата по расчету"
          paymentTypeText="Только после доставки вы оплачивает остаток и получаете товар"
          onShowPopupRequest={onShowPopupRequest}
        />

        <FindManufacturer
          vectorImageUrl="../vector-266.svg"
          openPopUpDonePopup={onShowPopupRequest}
        />
        <div className="questions">
          <div className="heading">
            <h2 className="h2">FAQ</h2>
          </div>
          <div className="questions__wrap">
            <AccordionQuestions />
            <QuestionsCard
              onShowPopupRequest={onShowPopupQuestion}
            />
          </div>
        </div>

        <Footer
          onShowPopupRequest={onShowPopupRequest}
          useNavLinkWithAnchor={true}
        />
      </div>
    </>
  );
};
export default Turnkey;
