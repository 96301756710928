import {FunctionComponent, useEffect, useState} from "react";
import "./RedioSwitch.scss"

const RadioSwitch: FunctionComponent = (props) => {
  const {radioCollection, radioName, activeValue, trigger} = props;
  const [active, setActive] = useState(activeValue)

  useEffect(() => {
    if (trigger) {
      trigger(active)
    }
  }, [active, trigger])

  return (
    <div className="tab-buttons">
      {radioCollection.map((radio) => {
        return (
          <label className={`tabs ${radio.value === active ? 'active' : ''}`} key={radio.id} onClick={() => { setActive(radio.value) }}>
            <input type="radio" name={radioName} onChange={() => { setActive(radio.value) }} checked={active === radio.value}/>
            <b className="b4">{radio.name}</b>
          </label>
        )
      })}
    </div>
  )
}

export default RadioSwitch;