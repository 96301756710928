import { FunctionComponent } from "react";
import Footer from "@components/footer/Footer";
import Header from "@components/header/header";
import {NavLink} from "react-router-dom";

// images
import BtnIcon from "@images/vector-2661.svg";
import ErrorImage from "@images/404@2x.png"
// styles
import "./ErrorPage.css";

const ErrorPage: FunctionComponent = (props) => {
  const {onShowPopupRequest, onHidePopupRequest} = props;

  return (
    <>
      <div className="div79 padding-header">

        <Header
          onShowPopupRequest={onShowPopupRequest}
          useNavLinkWithAnchor={true}
          whiteStyle={true}
        />

        <div className="page">
          <img className="icon2" alt="" src={ErrorImage} />
          <div className="headingsubtitle15">
            <h2 className="h24">Страница не найдена</h2>
            <div className="div85">
              Попробуйте обновить страницу или вернуться на главную
            </div>
          </div>
          <button className="button22">
            <NavLink to="/" className="b32">Вернуться на главную</NavLink>
            <img className="icons-16px7" alt="" src={BtnIcon} />
          </button>
        </div>
        <Footer
          onShowPopupRequest={onShowPopupRequest}
          useNavLinkWithAnchor={true}
        />
      </div>
    </>
  );
};

export default ErrorPage;
