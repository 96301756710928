import {FunctionComponent, useState} from "react";
import "@components/PopUpRequest.css";
import { send } from 'emailjs-com';
import { useForm } from "react-hook-form";
import $ from "jquery";
// import UI components
import RadioSwitch from "@components/ui/RadioSwitch/RadioSwitch";
import Input from "@components/ui/Input/Input";
import Textarea from "@components/ui/Textarea/Textarea";
import UploadFile from "@components/ui/UploadFile/UploadFile";
import Checkbox from "@components/ui/Checkbox/Checkbox";
// import images
import Close from "@images/icons-24px5.svg";
import SengRequestImage from "@images/icons-805.svg";
import BtnIcon from "@images/icons-16px5.svg";
// styles
import "../PopUpRequest.css";
import "../PopUpRequest2.css";
import "./PopUpDone.css";
import "./Popup.scss"

// const sgMail = require('@sendgrid/mail');
// sgMail.setApiKey('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');

type PopUpRequestType = {
  onClose?: () => void;
};

type Inputs = {
  type: number,
  name: string,
  phone: number,
  email: string,
  productName: string,
};

const PopupRequest: FunctionComponent<PopUpRequestType> = ({ onClose }) => {
  const [extendedState, setExtendedState] = useState(0); // 1/0
  const [sendRequestError, setSendRequestError] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);

  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm()<Inputs>;
  const onSubmit = (data) => {
    // send(
    //   'service_wsvdfxv',
    //   'template_tnvtscg',
    //   data,
    //   'X0HbyLwvE-i_4L9K1'
    // )
    //   .then((response) => {
    //     console.log('SUCCESS!', response.status, response.text);
    //     setSendRequest(true);
    //   })
    //   .catch((err) => {
    //     console.log('FAILED...', err);
    //     setSendRequestError(true);
    //   });

//     const msg = {
//       to: 'test@example.com',
//       from: 'test@example.com', // Use the email address or domain you verified above
//       subject: 'Sending with Twilio SendGrid is Fun',
//       text: 'and easy to do anywhere, even with Node.js',
//       html: '<strong>and easy to do anywhere, even with Node.js</strong>',
//     };
// //ES6
//     sgMail
//       .send(msg)
//       .then(() => {}, error => {
//         console.error(error);
//
//         if (error.response) {
//           console.error(error.response.body)
//         }
//       });
// //ES8
    const cookie = `; ${document.cookie}`;
    const parts = cookie.split(`; XSRF-TOKEN=`);
    const localHost = false; // Production
    // console.log(data)

    (async () => {
      await fetch((localHost ? 'http://localhost' : 'https://cnsup.ru') + '/api/send_mail', {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers:{
          'Authorization': btoa('AutolongPacificsourcing'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(function (response) {
        console.log(response.statusText);
        setSendRequest(true);
      })
      .catch (function (error) {
        console.log('Request failed', error);
        setSendRequestError(true);
      });
    })();
  };

  return (
    <>
      {sendRequestError ?
        <div className="pop-up-done">
          <div className="pop-up">
            <div className="headingsubtitle1">
              <b className="b2">Произошла ошибка!</b>
              <div className="div6">Попробуйте отправить позднее</div>
            </div>
            <button className="button1" onClick={onClose}>
              <b className="b3">Вернуться на сайт</b>
              <img className="icons-16px" alt="" src={BtnIcon}/>
            </button>
          </div>
        </div>
          :
        <>
          {sendRequest === false ?
            <div className="pop-up-request">
              <div className="pop-up2">
                <div className="closed" onClick={onClose}>
                  <img className="icons-24px3" alt="" src={Close}/>
                </div>
                <div className="content1">
                  <form onSubmit={handleSubmit(onSubmit)} className="headingsubtitle-group">
                    <div className="headingsubtitle3">
                      <h3 className="h31">Оставить заявку</h3>
                      <div className="div19">Заполните форму, а мы свяжемся с вами</div>
                    </div>
                    <div className="fields2">

                      <RadioSwitch
                        radioName="type"
                        trigger={setExtendedState}
                        activeValue={extendedState}
                        radioCollection={[
                          {
                            id: 0,
                            name: 'Обычная',
                            value: 0
                          },
                          {
                            id: 1,
                            name: 'Расширенная',
                            value: 1
                          }
                        ]}
                      />

                      <Input
                        labelName="ФИО"
                        fieldName="name"
                        register={register}
                        errors={errors}
                        errorMessage="Обязательно для заполнения"
                        onChangeValue={(val) => {
                          setValue('name', val)
                        }}
                      />

                      <div className="fields">
                        <Input
                          labelName="Телефон"
                          fieldName="phone"
                          register={register}
                          errors={errors}
                          errorMessage="Обязательно для заполнения"
                          onChangeValue={(val) => {
                            setValue('phone', val)
                          }}
                        />
                        <Input
                          type="email"
                          labelName="E-mail"
                          fieldName="email"
                          register={register}
                          errors={errors}
                          errorMessage="Обязательно для заполнения"
                          onChangeValue={(val) => {
                            setValue('email', val)
                          }}
                        />
                      </div>

                      {extendedState === 1 &&
                        <Input
                          labelName="Название продукции"
                          fieldName="productName"
                          register={register}
                          errors={errors}
                          errorMessage="Обязательно для заполнения"
                          onChangeValue={(val) => {
                            setValue('productName', val)
                          }}
                        />
                      }

                      <Textarea
                        labelName="Сообщение"
                        fieldName="message"
                        register={register}
                        errors={errors}
                        errorMessage="Обязательно для заполнения"
                        onChangeValue={(val) => {
                          setValue('message', val)
                        }}
                      />
                      {/*<UploadFile*/}
                      {/*  // onChangeValue={(val) => { data. = val }}*/}
                      {/*/>*/}

                    </div>
                    <div className="button4">
                      <button className="button5" type="submit">
                        <b className="b11">Оставить заявку</b>
                        <img className="icons-16px2" alt="" src={BtnIcon}/>
                      </button>

                      <Checkbox
                        fieldName="policy"
                        register={register}
                        errors={errors}
                        errorMessage="Обязательно для заполнения"
                        onChangeValue={(val) => {
                          setValue('policy', val)
                        }}
                      />

                    </div>
                  </form>
                </div>
              </div>
            </div>
            :
            <div className="pop-up-done">
              <div className="pop-up">
                <div className="icon">
                  <img className="icons-80" alt="" src={SengRequestImage}/>
                </div>
                <div className="headingsubtitle1">
                  <b className="b2">Заявка отправлена!</b>
                  <div className="div6">Скоро наш менеджер свяжется с вами</div>
                </div>
                <button className="button1" onClick={onClose}>
                  <b className="b3">Вернуться на сайт</b>
                  <img className="icons-16px" alt="" src={BtnIcon}/>
                </button>
              </div>
            </div>
          }
        </>
      }
  </>
  );
};

export default PopupRequest;
