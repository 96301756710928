import {FunctionComponent, useEffect, useState} from "react";
import "./Textarea.scss";
import {ErrorMessage} from "@hookform/error-message";

const Textarea:FunctionComponent = (props: {
  value: string;
}) => {
  const {
    labelName,
    fieldName,
    onChangeValue,
    value,
    register,
    errorMessage,
    errors
  } = props;

  const [inputValue, setValue] = useState(value ? value : '');
  const [isFocus, setFocus] = useState(false);

  useEffect(() => {
    if (onChangeValue) {
      onChangeValue(inputValue)
    }
  }, [inputValue])

  return (
    <div className={`custom-textarea ${isFocus ? 'custom-textarea_focus' : ''}`}>
      <label className={errors && fieldName in errors ? 'error' : '' }>
        <span className="custom-textarea__label-name">{labelName}</span>
        {register ?
          <>
            <textarea
              {...register(fieldName, {required: errorMessage})}
              value={inputValue}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                if (!inputValue.length) {
                  setFocus(false)
                }
              }}
              onChange={(e) => setValue(e.target.value) }
            />
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({message}) => <p className="custom-textarea__error">{message}</p>}
            />
          </>
            :
          <textarea
            value={inputValue}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              if (!inputValue.length) {
                setFocus(false)
              }
            }}
            onChange={(e) => setValue(e.target.value) }
          />
        }
      </label>
    </div>
  )
}

export default Textarea;