import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import Footer from "@components/footer/Footer";
import Header from "@components/header/header";
// images

// styles
import "./PolicyPage.css";

const PolicyPage: FunctionComponent = (props) => {
  const {onShowPopupRequest, onHidePopupRequest} = props;

  return (
    <>
      <div className="div71 padding-header">

        <Header
          onShowPopupRequest={onShowPopupRequest}
          whiteStyle={true}
        />

        <div className="personal-details">
          <div className="bredcrumbs">
            <div className="div77">
              <NavLink to="/">Главная</NavLink> / Политика обработки персональных данных
            </div>
          </div>
          <div className="heading3">
            <h2 className="h23">Политика обработки персональных данных</h2>
          </div>
          <div className="document">
            <div className="headingsubtitle12">
              <div className="heading4">
                <b className="b28">1. Общие положения</b>
              </div>
              <div className="httpsthismywebsitecom">
                <p className="p">
                  Настоящая политика обработки персональных данных составлена в
                  соответствии с требованиями Федерального закона от 27.07.2006.
                  №152-ФЗ «О персональных данных» (далее - Закон о персональных
                  данных) и определяет порядок обработки персональных данных и
                  меры по обеспечению безопасности персональных данных,
                  предпринимаемые Михайловым Иваном Сергеевичем (далее –
                  Оператор).
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  1.1. Оператор ставит своей важнейшей целью и условием
                  осуществления своей деятельности соблюдение прав и свобод
                  человека и гражданина при обработке его персональных данных, в
                  том числе защиты прав на неприкосновенность частной жизни,
                  личную и семейную тайну.
                </p>
                <p className="p">&nbsp;</p>
                <p className="httpsthismywebsitecom1">
                  1.2. Настоящая политика Оператора в отношении обработки
                  персональных данных (далее – Политика) применяется ко всей
                  информации, которую Оператор может получить о посетителях
                  веб-сайта httpsː//thismywebsite·com.
                </p>
              </div>
            </div>
            <div className="headingsubtitle12">
              <div className="heading4">
                <b className="b28">
                  2. Основные понятия, используемые в Политике
                </b>
              </div>
              <div className="httpsthismywebsitecom">
                <p className="p">
                  2.1. Автоматизированная обработка персональных данных –
                  обработка персональных данных с помощью средств вычислительной
                  техники.
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.2. Блокирование персональных данных – временное прекращение
                  обработки персональных данных (за исключением случаев, если
                  обработка необходима для уточнения персональных данных).
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.3. Веб-сайт – совокупность графических и информационных
                  материалов, а также программ для ЭВМ и баз данных,
                  обеспечивающих их доступность в сети интернет по сетевому
                  адресу httpsː//thismywebsite·com.
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.4. Информационная система персональных данных — совокупность
                  содержащихся в базах данных персональных данных, и
                  обеспечивающих их обработку информационных технологий и
                  технических средств.
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.5. Обезличивание персональных данных — действия, в
                  результате которых невозможно определить без использования
                  дополнительной информации принадлежность персональных данных
                  конкретному Пользователю или иному субъекту персональных
                  данных.
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.6. Обработка персональных данных – любое действие (операция)
                  или совокупность действий (операций), совершаемых с
                  использованием средств автоматизации или без использования
                  таких средств с персональными данными, включая сбор, запись,
                  систематизацию, накопление, хранение, уточнение (обновление,
                  изменение), извлечение, использование, передачу
                  (распространение, предоставление, доступ), обезличивание,
                  блокирование, удаление, уничтожение персональных данных.
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.7. Оператор – государственный орган, муниципальный орган,
                  юридическое или физическое лицо, самостоятельно или совместно
                  с другими лицами организующие и (или) осуществляющие обработку
                  персональных данных, а также определяющие цели обработки
                  персональных данных, состав персональных данных, подлежащих
                  обработке, действия (операции), совершаемые с персональными
                  данными.
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.8. Персональные данные – любая информация, относящаяся прямо
                  или косвенно к определенному или определяемому Пользователю
                  веб-сайта httpsː//thismywebsite·com.
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.9. Персональные данные, разрешенные субъектом персональных
                  данных для распространения, - персональные данные, доступ
                  неограниченного круга лиц к которым предоставлен субъектом
                  персональных данных путем дачи согласия на обработку
                  персональных данных, разрешенных субъектом персональных данных
                  для распространения в порядке, предусмотренном Законом о
                  персональных данных (далее - персональные данные, разрешенные
                  для распространения).
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.10. Пользователь – любой посетитель веб-сайта
                  httpsː//thismywebsite·com.
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.11. Предоставление персональных данных – действия,
                  направленные на раскрытие персональных данных определенному
                  лицу или определенному кругу лиц.
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.12. Распространение персональных данных – любые действия,
                  направленные на раскрытие персональных данных неопределенному
                  кругу лиц (передача персональных данных) или на ознакомление с
                  персональными данными неограниченного круга лиц, в том числе
                  обнародование персональных данных в средствах массовой
                  информации, размещение в информационно-телекоммуникационных
                  сетях или предоставление доступа к персональным данным
                  каким-либо иным способом.
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">
                  2.13. Трансграничная передача персональных данных – передача
                  персональных данных на территорию иностранного государства
                  органу власти иностранного государства, иностранному
                  физическому или иностранному юридическому лицу.
                </p>
                <p className="p">&nbsp;</p>
                <p className="httpsthismywebsitecom1">
                  2.14. Уничтожение персональных данных – любые действия, в
                  результате которых персональные данные уничтожаются
                  безвозвратно с невозможностью дальнейшего восстановления
                  содержания персональных данных в информационной системе
                  персональных данных и (или) уничтожаются материальные носители
                  персональных данных.
                </p>
              </div>
            </div>
            <div className="headingsubtitle12">
              <div className="heading4">
                <b className="b28">3. Основные права и обязанности Оператора</b>
              </div>
              <div className="httpsthismywebsitecom">
                <p className="p">3.1. Оператор имеет право:</p>
                <p className="p">
                  – получать от субъекта персональных данных достоверные
                  информацию и/или документы, содержащие персональные данные;
                </p>
                <p className="p">
                  – в случае отзыва субъектом персональных данных согласия на
                  обработку персональных данных Оператор вправе продолжить
                  обработку персональных данных без согласия субъекта
                  персональных данных при наличии оснований, указанных в Законе
                  о персональных данных;
                </p>
                <p className="p">
                  – самостоятельно определять состав и перечень мер, необходимых
                  и достаточных для обеспечения выполнения обязанностей,
                  предусмотренных Законом о персональных данных и принятыми в
                  соответствии с ним нормативными правовыми актами, если иное не
                  предусмотрено Законом о персональных данных или другими
                  федеральными законами.
                </p>
                <p className="p">&nbsp;</p>
                <p className="p">3.2. Оператор обязан:</p>
                <p className="p">
                  – предоставлять субъекту персональных данных по его просьбе
                  информацию, касающуюся обработки его персональных данных;
                </p>
                <p className="p">
                  – организовывать обработку персональных данных в порядке,
                  установленном действующим законодательством РФ;
                </p>
                <p className="p">
                  – отвечать на обращения и запросы субъектов персональных
                  данных и их законных представителей в соответствии с
                  требованиями Закона о персональных данных;
                </p>
                <p className="p">
                  – сообщать в уполномоченный орган по защите прав субъектов
                  персональных данных по запросу этого органа необходимую
                  информацию в течение 30 дней с даты получения такого запроса;
                </p>
                <p className="p">
                  – публиковать или иным образом обеспечивать неограниченный
                  доступ к настоящей Политике в отношении обработки персональных
                  данных;
                </p>
                <p className="p">
                  – принимать правовые, организационные и технические меры для
                  защиты персональных данных от неправомерного или случайного
                  доступа к ним, уничтожения, изменения, блокирования,
                  копирования, предоставления, распространения персональных
                  данных, а также от иных неправомерных действий в отношении
                  персональных данных;
                </p>
                <p className="p">
                  – прекратить передачу (распространение, предоставление,
                  доступ) персональных данных, прекратить обработку и уничтожить
                  персональные данные в порядке и случаях, предусмотренных
                  Законом о персональных данных;
                </p>
                <p className="httpsthismywebsitecom1">
                  – исполнять иные обязанности, предусмотренные Законом о
                  персональных данных.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer
          onShowPopupRequest={onShowPopupRequest}
          useNavLinkWithAnchor={true}
        />
      </div>
    </>
  );
};

export default PolicyPage;
