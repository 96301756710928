import { FunctionComponent } from "react";
import "./FindManufacturer.scss";

// import UI components
import Input from "@components/ui/Input/Input";
import Checkbox from "@components/ui/Checkbox/Checkbox";
// import images
import FindSupplier from "@images/frame-1038-1@2x.png";
import BtnIcon from "@images/icons-16px11.svg";
//

type CardVector266Type = {
  vectorImageUrl?: string;

  /** Action props */
  openPopUpDonePopup?: () => void;
};

const FindManufacturer: FunctionComponent<CardVector266Type> = ({
                                                               vectorImageUrl,
                                                               openPopUpDonePopup,
                                                             }) => {
  return (
    <div className="application">
      <div className="application1">
        <div className="headingfield">
          <div className="headingsubtitle26">
            <b className="b56">Хочу найти производителя!</b>
            <div className="div127">
              Оставьте заявку и менеджер перезвонит вам для уточнения всех
              деталей
            </div>
          </div>
          <div className="field19">
            <Input
              labelName="ФИО"
            />

            <Input
              labelName="Телефон"
            />

            <Input
              labelName="E-mail"
            />


            {/*<div className="field20">*/}
            {/*  <div className="namecontent19">*/}
            {/*    <div className="div128">ФИО</div>*/}
            {/*    <div className="div129">Иванов иван</div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="field21">*/}
            {/*  <div className="namecontent19">*/}
            {/*    <div className="div128">Телефон</div>*/}
            {/*    <div className="div131">+7 (499) 750-72-72</div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="field21">*/}
            {/*  <div className="namecontent19">*/}
            {/*    <div className="div128">E-mail</div>*/}
            {/*    <div className="div131">example@mail.ru</div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="button23">
              <button className="button24" onClick={openPopUpDonePopup}>
                <b className="b57">Оставить заявку</b>
                <img
                  className="icons-16px15"
                  alt=""
                  src={BtnIcon}
                />
              </button>

              <Checkbox />

              {/*<div className="icons-20px-parent2">*/}
              {/*  <div className="icons-20px16">*/}
              {/*    <div className="icons-20px-child1" />*/}
              {/*    <img className="vector-icon" alt="" src={vectorImageUrl} />*/}
              {/*  </div>*/}
              {/*  <div className="div134">*/}
              {/*    {`Я соглашаюсь на обработку `}*/}
              {/*    <span className="span2">персональных данных</span>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <img className="frame-1038-1" alt="" src={FindSupplier} />
      </div>
    </div>
  );
};

export default FindManufacturer;
