import { FunctionComponent, useCallback } from "react";
import "./MobileMenu.css";
import LogoBlack from "@images/svg/logo_default.svg";
import CloseIcon from "@images/svg/close-gray.svg";
import {NavLink} from "react-router-dom";

const MobileMenu: FunctionComponent = (props) => {
  const {useNavLink, onShowPopupRequest, onCloseMobileMenu} = props;

  return (
    <div className="div54">
      <div className="header1">
        <NavLink to="/" className="logo-al">
          <img className="logo-icon" alt="" src={LogoBlack} />
        </NavLink>
        <img className="icons-24px6" alt="" src={CloseIcon} onClick={onCloseMobileMenu}/>
      </div>
      <div className="menu">
        <div className="link1">

          {/*{useNavLink ?*/}
            <NavLink className="div57" to="/#howWork" onClick={onCloseMobileMenu}>Как это работает</NavLink>
            {/*<a className="div57" href="@components/header/MobileMenu#howWork" aria-hidden="true" onClick={onCloseMobileMenu}>Как это работает</a>*/}
          {/*}*/}

          <div className="parent">
            {/*{useNavLink ?*/}
              <NavLink className="div58" to="/#schemaWork" onClick={onCloseMobileMenu}>Схемы работы</NavLink>
              {/*<a className="div58" href="@components/header/MobileMenu#schemaWork" aria-hidden="true" onClick={onCloseMobileMenu}>Схемы работы</a>*/}
            {/*}*/}
            <NavLink className="div58 sublink" to="/schemes/consultation" onClick={onCloseMobileMenu}>Консультационная</NavLink>
            <NavLink className="div58 sublink" to="/schemes/turnkey" onClick={onCloseMobileMenu}>Под ключ</NavLink>
          </div>
          {/*{useNavLink ?*/}
            <NavLink className="div58" to="/#ourProjects" onClick={onCloseMobileMenu}>Наши проекты</NavLink>
            {/*<a className="div57" href="@components/header/MobileMenu#ourProjects" aria-hidden="true" onClick={onCloseMobileMenu}>Наши проекты</a>*/}
          {/*}*/}
          {/*{useNavLink ?*/}
            <NavLink className="div57" to="/#faq" onClick={onCloseMobileMenu}>FAQ</NavLink>
            {/*<a className="div57" href="@components/header/MobileMenu#faq" aria-hidden="true" onClick={onCloseMobileMenu}>FAQ</a>*/}
          {/*}*/}
        </div>
        <div className="button12" onClick={onShowPopupRequest}>
          <b className="div59">Оставить заявку</b>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
