import { FunctionComponent } from "react";

// images
import Lens from "@images/icons-801.svg";
import File from "@images/icons-8018.svg";
import StockImage from "@images/property-1-1@2x.png";
// styles
import "./QuestionsDiv.css";
//

const QuestionsDiv: FunctionComponent = () => {
  return (
    <div className="questions12">
      <div className="heading12">
        <h2 className="h26">Что вы получаете</h2>
      </div>
      <div className="stage-card-parent">
        <div className="stage-card6">
          <div className="stage-parent">
            <div className="stage7">
              <img className="icons-8010" alt="" src={Lens} />
              <div className="headingsubtitle29">
                <b className="b63">Находим поставщика в Китае</b>
                <div className="div143">
                  Контакты, предварительные договоренности, фото и видео,
                  переписки.
                </div>
              </div>
            </div>
            <div className="stage8">
              <img className="icons-8010" alt="" src={File} />
              <div className="headingsubtitle29">
                <b className="b63">Согласовываем сроки и цену</b>
                <div className="div143">Краткое описание гайда</div>
              </div>
            </div>
          </div>
          <div className="stage-group">
            <div className="stage9">
              <img className="icons-8010" alt="" src={Lens} />
              <div className="headingsubtitle29">
                <b className="b63">Привозим продукцию на свой контракт</b>
                <div className="div143">
                  Контакты, предварительные договоренности, фото и видео,
                  переписки.
                </div>
              </div>
            </div>
            <div className="stage9">
              <img className="icons-8010" alt="" src={File} />
              <div className="headingsubtitle29">
                <b className="b63">{`Закупка для осуществляется в России `}</b>
                <div className="div146">
                  Не нужно регистрироваться на таможне, искать брокера,
                  перевозчика, сравнивать цены и сроки, выбирать маршруты путей
                  доставки.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="stage11">
          <div className="headingsubtitle29">
            <b className="b67">
              Занимаемся таможней и перевозкой вашего заказа, взаимодействием с
              поставщиком в Китае
            </b>
            <div className="div147">Подзаголовок карточки</div>
          </div>
          <img
            className="component-1-icon"
            alt=""
            src={StockImage}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionsDiv;
