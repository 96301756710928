import { FunctionComponent, useState, useCallback } from "react";
import PopUpRequest from "../components/PopUpRequest";
import PortalPopup from "../components/PortalPopup";
import SubmitApplication from "@components/ui/Buttons/SubmitApplication";

// images
import KeyImages from "@images/--1@2x.png"
// styles
import "./SearchManufacturerForm.css";
//

const SearchManufacturerForm: FunctionComponent = (props) => {
  const {onShowPopupRequest, onHidePopupRequest} = props;

  return (
    <>
      <div className="home2">
        <div className="headingbutton2">
          <div className="tagheading12">
            <div className="tag">
              <b className="b61">Схема работы</b>
            </div>
            <div className="headingsubtitle28">
              <h1 className="h12">
                <p className="p28">Поиск производителя</p>
                <p className="p29">под ключ</p>
              </h1>
              <div className="div142">
                Закупка для осуществляется в России. Не нужно регистрироваться
                на таможне, искать брокера, перевозчика, сравнивать цены и
                сроки, выбирать маршруты путей доставки.
              </div>
            </div>
          </div>
          <SubmitApplication
            onShowPopupRequest={onShowPopupRequest}
          />
        </div>
        <img className="icon5" alt="" src={KeyImages} />
      </div>
    </>
  );
};

export default SearchManufacturerForm;
