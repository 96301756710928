import { FunctionComponent, useState, useCallback } from "react";
import PopUpQuestionDone from "../PopUpQuestionDone";
import PortalPopup from "../PortalPopup";
import "./PopUpQuestion.css";

// import images
import Close from "@images/icons-24px5.svg";
import Input from "@components/ui/Input/Input";
import Textarea from "@components/ui/Textarea/Textarea";
import Checkbox from "@components/ui/Checkbox/Checkbox";
import BtnIcon from "@images/icons-16px5.svg";
import {useForm} from "react-hook-form";
import {send} from "emailjs-com";
import SengRequestImage from "@images/icons-806.svg";
import $ from "jquery";

//

// const sgMail = require('@sendgrid/mail');
// sgMail.setApiKey('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');

type PopUpQuestionType = {
  onClose?: () => void;
};

type Inputs = {
  name: string,
  phone: number,
  email: string,
  message: string,
};

const PopUpQuestion: FunctionComponent<PopUpQuestionType> = ({ onClose }) => {
  const [sendRequestError, setSendRequestError] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);

  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm()<Inputs>;

  const onSubmit = (data) => {
    // send(
    //   'service_wsvdfxv',
    //   'template_jvguz7n',
    //   data,
    //   'X0HbyLwvE-i_4L9K1'
    // )
    //   .then((response) => {
    //     console.log('SUCCESS!', response.status, response.text);
    //     setSendRequest(true);
    //   })
    //   .catch((err) => {
    //     console.log('FAILED...', err);
    //     setSendRequestError(true);
    //   });
//     const msg = {
//       to: 'site@pacificsourcing.ru',
//       from: 'noreply@pacificsourcing.ru', // Use the email address or domain you verified above
//       subject: 'Заявка с сайта',
//       text: 'and easy to do anywhere, even with Node.js',
//       html: '<strong>and easy to do anywhere, even with Node.js</strong>',
//     };
// //ES6
//     sgMail
//       .send(msg)
//       .then((response) => {
//         console.log('SUCCESS!', response);
//         setSendRequest(true);
//       }, error => {
//         console.error(error);
//         setSendRequestError(true);
//
//         if (error.response) {
//           console.error(error.response.body)
//         }
//       });
// //ES8
    const cookie = `; ${document.cookie}`;
    const parts = cookie.split(`; XSRF-TOKEN=`);
    console.log(data)
    $.ajax({
      // url: '/api/prequests',
      url: 'https://cnsup.ru/api/send_mail',
      method: 'POST',
      data: JSON.stringify(data),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
      //   'X-XSRF-TOKEN': parts.length === 2 ? parts.pop().split(';').shift() : '',
      //   'Authorization': 'Bearer ' + localStorage.getItem('token')
        'Authorization': btoa('AutolongPacificsourcing')
      },
      cache: false,
      processData: false
    })
      .done(function(response) {
        console.log('SUCCESS!', response);
        setSendRequest(true);
      })
      .fail(function(error) {
        console.error(error);
        setSendRequestError(true);
      });
  };

  return (
    <>
      {sendRequestError ?
        <div className="pop-up-done">
          <div className="pop-up">
            <div className="headingsubtitle1">
              <b className="b2">Произошла ошибка!</b>
              <div className="div6">Попробуйте отправить позднее</div>
            </div>
            <button className="button1" onClick={onClose}>
              <b className="b3">Вернуться на сайт</b>
              <img className="icons-16px" alt="" src={BtnIcon}/>
            </button>
          </div>
        </div>
        :
        <>
          {sendRequest === false ?
            <div className="pop-up-question">
              <div className="pop-up2">
                <div className="closed" onClick={onClose}>
                  <img className="icons-24px4" alt="" src={Close}/>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="content2">
                  <div className="headingsubtitle-container">
                    <div className="headingsubtitle5">
                      <h3 className="h32">оставить вопрос</h3>
                      <div className="div32">
                        Заполните форму, а мы свяжемся с вами и ответим на все вопросы
                      </div>
                    </div>
                    <div className="fields4">

                      <Input
                        labelName="ФИО"
                        fieldName="name"
                        register={register}
                        errors={errors}
                        errorMessage="Обязательно для заполнения"
                        onChangeValue={(val) => {
                          setValue('name', val)
                        }}
                      />

                      <div className="fields">
                        <Input
                          labelName="Телефон"
                          fieldName="phone"
                          register={register}
                          errors={errors}
                          errorMessage="Обязательно для заполнения"
                          onChangeValue={(val) => {
                            setValue('phone', val)
                          }}
                        />
                        <Input
                          type="email"
                          labelName="E-mail"
                          fieldName="email"
                          register={register}
                          errors={errors}
                          errorMessage="Обязательно для заполнения"
                          onChangeValue={(val) => {
                            setValue('email', val)
                          }}
                        />
                      </div>


                      <Textarea
                        labelName="Сообщение"
                        fieldName="message"
                        register={register}
                        errors={errors}
                        errorMessage="Обязательно для заполнения"
                        onChangeValue={(val) => {
                          setValue('message', val)
                        }}
                      />

                    </div>
                    <div className="button7">
                      <button className="button5" type="submit">
                        <b className="b11">Отправить вопрос</b>
                        <img className="icons-16px2" alt="" src={BtnIcon}/>
                      </button>

                      <Checkbox
                        fieldName="policy"
                        register={register}
                        errors={errors}
                        errorMessage="Обязательно для заполнения"
                        onChangeValue={(val) => {
                          setValue('policy', val)
                        }}
                      />

                    </div>
                  </div>
                </form>
              </div>
            </div>
            :
            <div className="pop-up-done">
              <div className="pop-up">
                <div className="icon">
                  <img className="icons-80" alt="" src={SengRequestImage}/>
                </div>
                <div className="headingsubtitle1">
                  <b className="b2">Вопрос отправлен!</b>
                  <div className="div6">Скоро наш менеджер свяжется с вами</div>
                </div>
                <button className="button1" onClick={onClose}>
                  <b className="b3">Вернуться на сайт</b>
                  <img className="icons-16px" alt="" src={BtnIcon}/>
                </button>
              </div>
            </div>
          }
        </>
      }
    </>
  );
};

export default PopUpQuestion;
