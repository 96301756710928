import {FunctionComponent, useEffect, useState} from "react";
import "./Input.scss";
import { ErrorMessage } from '@hookform/error-message';

const Input:FunctionComponent = (props: {
  value: string;
  type?: string | "text",
  errorMessage: string | 'Required'
}) => {
  const {
    labelName,
    fieldName,
    onChangeValue,
    value,
    type,
    register,
    errorMessage,
    errors
  } = props;

  const [inputValue, setValue] = useState(value ? value : '');
  const [isFocus, setFocus] = useState(false);

  useEffect(() => {
    if (onChangeValue) {
      onChangeValue(inputValue)
    }
  }, [inputValue])

  return (
    <div className={`custom-input ${isFocus ? 'custom-input_focus' : ''}`}>
      <label className={errors && fieldName in errors ? 'error' : '' }>
        <span className="custom-input__label-name">{labelName}</span>
        {register ?
          <>
            <input
              {...register(fieldName, {required: errorMessage})}
              name={fieldName}
              type={type}
              value={inputValue}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                if (!inputValue.length) {
                  setFocus(false)
                }
              }}
              onChange={(e) => setValue(e.target.value)}
            />
            {/*{inputValue.length && errors ?*/}
              <ErrorMessage
                errors={errors}
                name={fieldName}
                render={({message}) => <p className="custom-input__error">{message}</p>}
              />
            {/*: ''*/}
            {/*}*/}
          </>
          :
          <input
            name={fieldName}
            type={type}
            value={inputValue}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              if (!inputValue.length) {
                setFocus(false)
              }
            }}
            onChange={(e) => setValue(e.target.value)}
          />
        }

      </label>
    </div>
  )
}

export default Input;