import { FunctionComponent } from "react";
import "./QuestionsForm.css";

// images
import Lens from "@images/icons-801.svg";
import File from "@images/icons-8018.svg";
import Contact from "@images/icons-8019.svg";
import PlantImage from "@images/--11@2x.png";
//

const QuestionsForm: FunctionComponent = () => {
  return (
    <div className="questions14">
      <div className="heading14">
        <h2 className="h28">Что вы получаете</h2>
      </div>
      <div className="stage-card-group">
        <div className="stage-card7">
          <div className="stage-container">
            <div className="stage12">
              <img className="icons-8015" alt="" src={Lens} />
              <div className="headingsubtitle35">
                <b className="b72">Даем информацию о производителе</b>
                <div className="div150">
                  Контакты, предварительные договоренности, фото и видео,
                  переписки.
                </div>
              </div>
            </div>
            <div className="stage13">
              <img className="icons-8015" alt="" src={File} />
              <div className="headingsubtitle35">
                <b className="b72">Гайд как осуществить импорт самому</b>
                <div className="div151">Краткое описание гайда</div>
              </div>
            </div>
          </div>
          <div className="stage14">
            <img className="icons-8017" alt="" src={Contact} />
            <div className="headingsubtitle35">
              <b className="b74">
                Контакты, информация по перевозке, таможенному оформлению заказа
              </b>
              <div className="div152">*Зависит от выбранного схемы работы</div>
            </div>
          </div>
        </div>
        <div className="stage15">
          <div className="headingsubtitle35">
            <b className="b75">Находим поставщика в китае</b>
            <div className="div153">Подзаголовок карточки</div>
          </div>
          <img className="icon6" alt="" src={PlantImage} />
        </div>
      </div>
    </div>
  );
};

export default QuestionsForm;
