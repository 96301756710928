import {FunctionComponent, useState, useEffect} from "react";
import "./Footer.css";
import { HashLink as Link } from "react-router-hash-link";

// import images
import Logo from "@images/svg/logo_white.svg";
import {NavLink} from "react-router-dom";
import classNames from "*.css";
//

type FooterType = {
  /** Action props */
  onShowPopupRequest?: () => void;
};

const Footer: FunctionComponent<FooterType> = (props: {
   onShowPopupRequest: any,
  useNavLinkWithAnchor: any
}) => {
  const {useNavLinkWithAnchor, onShowPopupRequest} = props;

  const [useNavLink] = useState(useNavLinkWithAnchor);

  const [linkDelaemIT, setDelaemIT] = useState(false)

  var XMLHttpRequest = require('xhr2')
  var xhr = new XMLHttpRequest()

  useEffect(() => {
    if (!linkDelaemIT) {
      xhr.open('GET', 'https://delaemit.ru/actions/logo.php?client=autolong-provider', true)
      xhr.send()

      xhr.onreadystatechange = function () {
        if (xhr.status === 200) {
          setDelaemIT(xhr.responseText)
        }
      }
    }
  }, [])

  return (
    <footer className="footer">
      <div className="footer1">
        <div className="logolink">
          <NavLink to="/" className="logo">
            <div className="div137">
              <img className="icon4" alt="" src={Logo} />
            </div>
          </NavLink>
          <div className="link7">
            {/*{useNavLink ?*/}
              <Link className="a" to="/#howWork">Как это работает</Link>
              {/*<a className="a8" href="/site_providers#howWork" aria-hidden="true">Как это работает</a>*/}
            {/*}*/}
            {/*{useNavLink ?*/}
              <Link className="a" to="/#schemaWork">Схемы работы</Link>
              {/*<a className="a8" href="/site_providers#schemaWork" aria-hidden="true">Схемы работы</a>*/}
            {/*}*/}
            {/*{useNavLink ?*/}
              <Link className="a" to="/#ourProjects">Наши проекты</Link>
              {/*<a className="a8" href="/site_providers#ourProjects" aria-hidden="true">Наши проекты</a>*/}
            {/*}*/}
            {/*{useNavLink ?*/}
              <Link className="a" to="/#faq">FAQ</Link>
              {/*<a className="a8" href="/site_providers#faq" aria-hidden="true">FAQ</a>*/}
            {/*}*/}
          </div>
        </div>
        <div className="phonebutton">
          <div className="phone">
            <div className="div138">Связаться с нами</div>
            <a href="tel:+7 (499) 750-72-72" className="b59">+7 (499) 750-72-72</a>
            <a href="mailto:statut.help@mail.ru" className="statuthelpmailru">statut.help@mail.ru</a>
          </div>
          <div className="phone1">
            <div className="div139">Хотите найти производителя?</div>
            <button className="button25" onClick={onShowPopupRequest}>
              <b className="b60">Оставить заявку</b>
            </button>
          </div>
        </div>
      </div>
      <div className="footer-child" />
      <div className="links">
        <div className="links1">
          <div className="pacificsourcing">
            @ 2023 PacificSourcing
          </div>
          <NavLink to="/policy" className="pacificsourcing">
            Политика конфиденциальности
          </NavLink>
        </div>
        <div className="div141">
          {
            linkDelaemIT ? (
                <div
                    className={ 'text-[0.875rem] text-gray min-w-[12rem] footer_delaem_logo'}
                    //@ts-ignore
                    dangerouslySetInnerHTML={{__html: linkDelaemIT}}
                />
            ) : ''
          }
        </div>
      </div>
    </footer>
  );
};

export default Footer;
