import { FunctionComponent, useMemo } from "react";
import CSS, { Property } from "csstype";
import "./BtnPrimary.css";

type BtnPrimaryType = {
  prop?: string;
  icons16px?: string;

  /** Style props */
  buttonAlignSelf?: Property.AlignSelf;
  buttonBoxSizing?: Property.BoxSizing;
  propDisplay?: Property.Display;

  /** Action props */
  openPopUpRequestPopup?: () => void;
};

const BtnPrimary: FunctionComponent<BtnPrimaryType> = ({
  openPopUpRequestPopup,
  buttonAlignSelf,
  prop,
  icons16px,
  buttonBoxSizing,
  propDisplay,
}) => {
  const buttonStyle: CSS.Properties = useMemo(() => {
    return {
      alignSelf: buttonAlignSelf,
      boxSizing: buttonBoxSizing,
    };
  }, [buttonAlignSelf, buttonBoxSizing]);

  const bStyle: CSS.Properties = useMemo(() => {
    return {
      display: propDisplay,
    };
  }, [propDisplay]);

  return (
    <button
      className="button22"
      onClick={openPopUpRequestPopup}
      style={buttonStyle}
    >
      <b className="b47" style={bStyle}>
        {prop}
      </b>
      <img className="icons-16px10" alt="" src={icons16px} />
    </button>
  );
};

export default BtnPrimary;
