import {FunctionComponent, useState} from "react";

import "./Checkbox.scss"
import {ErrorMessage} from "@hookform/error-message";

const Checkbox:FunctionComponent = (props) => {
  const [checked, setChecked] = useState(false);

  const {
    register,
    errorMessage,
    errors,
    fieldName
  } = props;

  return (
    <label className={`custom-checkbox ${checked ? 'custom-checkbox_checked' : ''} ${errors && fieldName in errors ? 'error' : ''}`}>
      <div className="icons-20px-group">
        <div className="icons-20px4">
          <div className="icons-20px-item" />
        </div>
        <div className="div30">
          Я соглашаюсь на обработку персональных данных
        </div>
      </div>
      {register ?
        <>
          <input
            {...register(fieldName, {required: errorMessage})}
            type="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <ErrorMessage
            errors={errors}
            name={fieldName}
            render={({message}) => <p className="custom-checkbox__error">{message}</p>}
          />
        </>
        :
        <input
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
      }

    </label>
  )
}

export default Checkbox;