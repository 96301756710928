import { FunctionComponent, useMemo } from "react";
import CSS, { Property } from "csstype";
import "./AdvantagesCard.css";

type AdvantagesCardType = {
  icons80?: string;
  prop?: string;

  /** Style props */
  stageAlignSelf?: Property.AlignSelf;
  propDisplay?: Property.Display;
};

const AdvantagesCard: FunctionComponent<AdvantagesCardType> = ({
  stageAlignSelf,
  icons80,
  prop,
  propDisplay,
}) => {
  const stageStyle: CSS.Properties = useMemo(() => {
    return {
      alignSelf: stageAlignSelf,
    };
  }, [stageAlignSelf]);

  const divStyle: CSS.Properties = useMemo(() => {
    return {
      display: propDisplay,
    };
  }, [propDisplay]);

  return (
    <div className="stage6" style={stageStyle}>
      <img className="icons-808" alt="" src={icons80} />
      <div className="headingsubtitle18">
        <b className="b48">{prop}</b>
        <div className="div106" style={divStyle}>
          Мы связываемся с вами для уточнения всех деталей заказа, заключаем
          договор.
        </div>
      </div>
    </div>
  );
};

export default AdvantagesCard;
