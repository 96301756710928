import BtnIcon from "@images/vector-2661.svg";
import BtnPrimary from "@components/BtnPrimary";

const SubmitApplication = (props?) => {
  const {onShowPopupRequest} = props;

  return (
    <BtnPrimary
      openPopUpRequestPopup={onShowPopupRequest}
      prop="Оставить заявку"
      icons16px={BtnIcon}
    />
  )
}

export default SubmitApplication;

