import {FunctionComponent, useEffect} from "react";
import AdvantagesCard from "@components/AdvantagesCard";
import ProjectsCard from "@components/ProjectsCard";
import CarTintCard from "@components/CarTintCard";
import ProjectsForm from "@components/ProjectsForm";
import FindManufacturer from "@components/FindManufacturer/FindManufacturer";
import QuestionsCard from "@components/QuestionsCard";
import Header from "@components/header/header";
import Footer from "@components/footer/Footer";
import "./IndexPage.css";

import AccordionQuestions from "@components/accordion/AccordionQuestions";
import SubmitApplication from "@components/ui/Buttons/SubmitApplication";

// import images
import IconFactory from "@images/image-factory-2-1@2x.png";
import Lens from "@images/icons-801.svg";
import Car from "@images/icons-802.svg";
//

const IndexPage: FunctionComponent = (props) => {
  const {onShowPopupRequest, onHidePopupRequest, onShowPopupQuestion, onHidePopupQuestion} = props;

  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });

  return (
    <>
      <div className="div padding-header">
        <Header
          onShowPopupRequest={onShowPopupRequest}
        />

        <div className="home">
          <div className="headingbutton">
            <div className="headingsubtitle">
              <h1 className="h1">
                <span>{`Найдем производителя товаров `}</span>
                <span className="span">{`в Китае `}</span>
              </h1>
              <div className="div4">
                Выбираете схему работы, оставляете заявку
              </div>
            </div>

            <SubmitApplication
              onShowPopupRequest={onShowPopupRequest}
            />

          </div>
          <img
            className="image-factory-2-1"
            alt=""
            src={IconFactory}
          />
        </div>
        <div className="stage">
          <div className="heading">
            <h2 className="h2" id="howWork">Как это работает?</h2>
          </div>
          <div className="stage-card">
            <div className="stage-card1">
              <AdvantagesCard
                icons80={Lens}
                prop="Выбираете схему работы, оставляете заявку"
              />
              <AdvantagesCard
                stageAlignSelf="stretch"
                icons80={Car}
                prop="Ищем производителей в Китае по вашему запросу"
                propDisplay="none"
              />
            </div>
            <div className="stage-card2">
              <AdvantagesCard
                stageAlignSelf="stretch"
                icons80={Lens}
                prop="Заключаем договор и импортируем* продукцию в Россию"
              />
              <AdvantagesCard
                stageAlignSelf="stretch"
                icons80={Lens}
                prop="Получаете заказ!"
              />
            </div>
          </div>
        </div>
        <div className="projects" id="ourProjects">
          <h2 className="h21">Наши проекты</h2>
          <div className="projects-card">
            <ProjectsCard />
            <CarTintCard />
          </div>
        </div>

        <ProjectsForm />

        <FindManufacturer
          vectorImageUrl="../vector-266.svg"
          openPopUpDonePopup={onShowPopupRequest}
        />

        <div className="questions">
          <div className="heading">
            <h2 className="h2" id="faq">FAQ</h2>
          </div>
          <div className="questions__wrap">
            <AccordionQuestions />
            <QuestionsCard
              onShowPopupRequest={onShowPopupQuestion}
            />
          </div>
        </div>

        <Footer
          onShowPopupRequest={onShowPopupRequest}
        />
      </div>
    </>
  );
};

export default IndexPage;
