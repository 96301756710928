import { FunctionComponent, useState, useCallback } from "react";
import PopUpRequest from "../components/PopUpRequest";
import PortalPopup from "../components/PortalPopup";
import "./ConsultationSupportDiv.css";
import BtnPrimary from "@components/BtnPrimary";

// images
import BtnIcon from "@images/vector-2661.svg";
import ConsultImage from "@images/chat-1@2x.png";
//

const ConsultationSupportDiv: FunctionComponent = (props) => {
  const {onShowPopupRequest, onHidePopupRequest} = props;

  return (
    <>
      <div className="home3">
        <div className="headingbutton3">
          <div className="tagheading13">
            <div className="tag1">
              <b className="b70">Схема работы</b>
            </div>
            <div className="headingsubtitle34">
              <h1 className="h13">Консультационная поддержка</h1>
              <div className="div149">
                <p className="p30">{`Найдем всю информацию о производителе, договоримся о цене и поставке, `}</p>
                <p className="p31">поможет с оформлением импорта в Россию.</p>
              </div>
            </div>
          </div>
          <BtnPrimary
            openPopUpRequestPopup={onShowPopupRequest}
            prop="Оставить заявку"
            icons16px={BtnIcon}
          />
        </div>
        <img className="chat-1-icon" alt="" src={ConsultImage} />
      </div>

    </>
  );
};

export default ConsultationSupportDiv;
